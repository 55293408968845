import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateRange } from '../utils/dateUtils';

interface Experience {
    company: string;
    logo: string;
    role: string;
    startDate: string;
    endDate?: string;
    location: string;
    description: string;
    skills: string[];
}

const experiences: Experience[] = [
    {
        company: "Sharewares",
        logo: "https://media.licdn.com/dms/image/v2/D560BAQFya_cZRTVRGA/company-logo_100_100/company-logo_100_100/0/1698691209601/sharewares_logo?e=1750291200&v=beta&t=eCegEdDGdrbxm_5thDc2NFeqylTf697MmBmgTkydRsw",
        role: "Senior Software Engineer",
        startDate: "2024-12",
        location: "Vancouver, British Columbia, Canada",
        description: "ERP systems for reusables",
        skills: ["React.js", "Node.js", "AWS"]
    },
    {
        company: "Keyhole",
        logo: "https://media.licdn.com/dms/image/v2/C4D0BAQFvgZwvWnKn_w/company-logo_100_100/company-logo_100_100/0/1672731471355/keyhole_co_logo?e=1741219200&v=beta&t=YYLgTVL9lR7oezINQbyC3MzyjgRrfSMFsCxE1Tanymk",
        role: "Senior Software Engineer, Data Science",
        startDate: "2023-08",
        endDate: "2024-10",
        location: "Vancouver, British Columbia, Canada",
        description: "worked on the pricing, feature discovery, smart notifications and auto caption generation",
        skills: ["LLMs", "RAG", "React.js", "Node.js", "Python", "AWS"]
    },
    {
        company: "Hubpay",
        logo: "https://media.licdn.com/dms/image/v2/C4D0BAQF9KShh8-lmLQ/company-logo_100_100/company-logo_100_100/0/1668604080465/hubpay_logo?e=1741219200&v=beta&t=uihBErLlD8f_Owu2s8emxCAYVfUv82RlAosT1j3P454",
        role: "Software Engineer II, Data",
        startDate: "2022-08",
        endDate: "2023-09",
        location: "Dubai, United Arab Emirates",
        description: "worked on pricing and promo models and other backend services",
        skills: ["SQL", "FASTAPI", "SageMaker", "A/B Testing"]
    },
    {
        company: "Combyne",
        logo: "https://media.licdn.com/dms/image/v2/D4E0BAQFbblfR832A-A/company-logo_100_100/company-logo_100_100/0/1686314664721?e=1741219200&v=beta&t=ZbulFW37fjfXUqHmlQYmmfyaFUUgrisEd160CAm2xn4",
        role: "Data Engineer",
        startDate: "2021-01",
        endDate: "2022-09",
        location: "Abu Dhabi, United Arab Emirates",
        description: "worked on churn analysis, feature importance, image segmentation and classification models",
        skills: ["SQL", "Google Cloud Platform (GCP)", "GANs", "A/B Testing"]
    },
    {
        company: "New York University Abu Dhabi",
        logo: "https://media.licdn.com/dms/image/v2/C4D0BAQEr7NDNythPxw/company-logo_100_100/company-logo_100_100/0/1631314170855?e=1741219200&v=beta&t=tJKw1s64lh0PXBgBUiIuAHVEgqXsSVoEUwPonLE-BMY",
        role: "Research Assistant",
        startDate: "2019-03",
        endDate: "2021-05",
        location: "Abu Dhabi, United Arab Emirates",
        description: "worked with Dr. Yasir Zaki, Dr. Andrew Harris across multiple projects; Published 5 papers (2 first-authored)",
        skills: ["Python", "Research", "Data Analysis"]
    }
];

const WorkHistory: React.FC = () => {
    return (
        <section id="work-history" className="py-16 bg-white scroll-mt-20">
            <div className="max-w-4xl mx-auto px-4">
                <div className="flex justify-between items-center mb-12">
                    <h2 className="font-display text-heading font-semibold text-gray-900 tracking-tight">
                        Work History
                    </h2>
                    <a
                        href="https://www.linkedin.com/in/waleedhashmi"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-2 text-[#0A66C2] hover:text-[#004182] transition-colors text-small font-medium"
                    >
                        <FontAwesomeIcon icon={['fab', 'linkedin']} className="text-xl" />
                        <span>View on LinkedIn</span>
                    </a>
                </div>

                <div className="space-y-6">
                    {experiences.map((exp, index) => (
                        <div
                            key={index}
                            className="bg-gray-50 rounded-lg p-6 hover:bg-gray-100 transition-colors"
                        >
                            <div className="flex items-start gap-5">
                                <img
                                    src={exp.logo}
                                    alt={`${exp.company} logo`}
                                    className="w-12 h-12 rounded-full object-cover flex-shrink-0"
                                />
                                <div className="flex-1 space-y-3">
                                    <div className="flex justify-between items-start">
                                        <div>
                                            <h3 className="text-title font-semibold text-gray-900 tracking-tight">
                                                {exp.company}
                                            </h3>
                                            <p className="text-body font-medium text-gray-700">
                                                {exp.role}
                                            </p>
                                        </div>
                                        <div className="text-right">
                                            <p className="text-small font-medium text-gray-600">
                                                {formatDateRange(exp.startDate, exp.endDate)}
                                            </p>
                                            <p className="text-small text-gray-500">
                                                {exp.location}
                                            </p>
                                        </div>
                                    </div>
                                    <p className="text-small text-gray-600 leading-relaxed">
                                        {exp.description}
                                    </p>
                                    <div className="flex flex-wrap gap-2">
                                        {exp.skills.map((skill, skillIndex) => (
                                            <span
                                                key={skillIndex}
                                                className="bg-gray-200/70 text-gray-700 px-2.5 py-1 rounded-full text-xs font-medium"
                                            >
                                                {skill}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default WorkHistory; 